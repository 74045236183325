import { useQuery } from '@tanstack/react-query'
import Workflow from 'types/workflow'
import apiAsync from 'utils/api-async'

type FetchWorkflowResponse = {
  data?: {
    workflow: Workflow
  }
  success: boolean
  message: string
}

export const fetchWorkflow = (
  workflowUuid: string | null,
  showLoading = true
): Promise<FetchWorkflowResponse> => {
  if (!workflowUuid) {
    return Promise.resolve({
      success: false,
      message: 'Workflow not found'
    })
  }

  return apiAsync.requestSync({
    endpoint: 'workflows',
    path: workflowUuid,
    method: 'GET',
    showLoading: showLoading
  })
}

export default function useWorkflow({
  workflowUuid
}: {
  workflowUuid: string | null
}) {
  const { data: response, isFetching } = useQuery<FetchWorkflowResponse>({
    queryKey: ['workflow', workflowUuid],
    queryFn: () => {
      return fetchWorkflow(workflowUuid)
    }
  })

  return {
    workflow: response?.data?.workflow,
    isFetching
  }
}
