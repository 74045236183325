import {
  ActivityGroup,
  FlatActivityModel,
  FlatInteractionModelData,
  InteractionModelData,
  InteractionModelItemGroup
} from 'types/shared'

const removeUndefinedProperties = <T extends object>(input: T): T =>
  Object.fromEntries(
    Object.entries(input).filter(([_, value]) => value !== undefined)
  ) as T

const activityGroupModelToFlatEntries = (
  activityModel: ActivityGroup
): [string, FlatActivityModel][] => {
  const groupActivityModel = {
    ...activityModel,
    activity_models: (activityModel.activity_models[0] || []).map(
      (groupActivity) => groupActivity.originalUuid
    )
  } as FlatActivityModel

  const activityModelsInGroup = (activityModel.activity_models[0] || []).map(
    ({
      value: _ignored,
      validationStatus: _ignored2,
      helperText: _ignored3,
      uuid: _ignored4,
      originalUuid,
      ...groupActivity
    }) => [originalUuid, { ...groupActivity, uuid: originalUuid }]
  ) as [string, FlatActivityModel][]

  return [[activityModel.uuid, groupActivityModel], ...activityModelsInGroup]
}

const flatActivityModelsFromSteps = (
  steps: InteractionModelItemGroup[]
): FlatInteractionModelData['activity_models'] => {
  const flatActivityModelsEntries: [string, FlatActivityModel][] =
    steps.flatMap((item) => {
      const entries: [string, FlatActivityModel][] =
        item.activity_models.flatMap(
          ({
            value: _ignored,
            validationStatus: _ignored2,
            helperText: _ignored3,
            originalUuid: _ignored4,
            ...activityModel
          }) => {
            if (activityModel.content_type === 'activity_group') {
              return activityGroupModelToFlatEntries(activityModel)
            }

            return [[activityModel.uuid, activityModel]]
          }
        )

      return entries
    })

  const flatActivityModels = Object.fromEntries(flatActivityModelsEntries)

  return flatActivityModels
}

const flatActivityValuesFromSteps = (
  steps: InteractionModelItemGroup[]
): FlatInteractionModelData['activity_values'] => {
  const flatActivityValuesEntries: [
    string,
    FlatInteractionModelData['activity_values']['']
  ][] = steps.flatMap((item) => {
    const entries: [string, FlatInteractionModelData['activity_values']['']][] =
      item.activity_models.flatMap((activityModel) => {
        if (activityModel.value !== undefined) {
          return [[activityModel.uuid, activityModel.value]]
        }

        if (
          activityModel.content_type === 'activity_group' &&
          activityModel.activity_models.length
        ) {
          const groupedActivityValues = activityModel.activity_models.map(
            (groupedActivityModels) =>
              Object.fromEntries(
                groupedActivityModels.flatMap((activityModelInGroup) =>
                  activityModelInGroup.value === undefined
                    ? []
                    : [
                        [
                          activityModelInGroup.originalUuid,
                          activityModelInGroup.value
                        ]
                      ]
                )
              )
          ) as FlatInteractionModelData['activity_values']['']

          return [[activityModel.uuid, groupedActivityValues]]
        }

        return []
      })

    return entries
  })

  const flatActivityValues = Object.fromEntries(flatActivityValuesEntries)

  return flatActivityValues
}

export const interactionModelDataToFlat = (
  data: InteractionModelData
): FlatInteractionModelData => {
  const clonedData: InteractionModelData = JSON.parse(JSON.stringify(data))

  const {
    uuid,
    des,
    notification_topic_arn,
    language,
    initial_step_uuid,
    blueprints,
    validation_callback,
    information_cards,
    automations,
    interaction_model_item_groups
  } = clonedData

  const flatInteractionModelItemGroups: FlatInteractionModelData['interaction_model_item_groups'] =
    interaction_model_item_groups.map((item) =>
      removeUndefinedProperties({
        uuid: item.uuid,
        des: item.des,
        order: item.order,
        complement: item.complement,
        explanation_text: item.explanation_text,
        validation_group_callback: item.validation_group_callback,
        activity_models: item.activity_models.map(
          (activityModel) => activityModel.uuid
        )
      })
    )

  const result = removeUndefinedProperties({
    uuid,
    des,
    notification_topic_arn,
    language,
    initial_step_uuid,
    blueprints,
    validation_callback,
    information_cards,
    automations,

    interaction_model_item_groups: flatInteractionModelItemGroups,
    activity_models: flatActivityModelsFromSteps(interaction_model_item_groups),
    activity_values: flatActivityValuesFromSteps(interaction_model_item_groups)
  })

  return result
}
