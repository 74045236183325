import debounce from 'lodash.debounce'
import { useCallback, useEffect, useMemo } from 'react'

import { VALIDATION_STATUSES } from '../../../utils/constants'
import { interactionModelOperations } from '../redux'
import { InteractionModelData } from 'types/shared'
import { useAppDispatch } from 'state/store'
import { validateUsingCallback } from '../utils/validateUsingCallback'

const { updateActivityModelStatus } = interactionModelOperations

export default function useValidationCallbacks(
  interactionModelData?: InteractionModelData
) {
  const dispatch = useAppDispatch()

  const callPendingValidationCallbacks = useCallback(
    (oldInteractionModelData?: InteractionModelData) => {
      const steps = oldInteractionModelData?.interaction_model_item_groups || []

      steps.forEach((step) => {
        step.activity_models.forEach((activityModel) => {
          if (activityModel.validationStatus === VALIDATION_STATUSES.PENDING) {
            validateUsingCallback(activityModel).then(
              ({ validationStatus, helperText }) => {
                dispatch(
                  updateActivityModelStatus({
                    activityModelUuid: activityModel.uuid,
                    validationStatus,
                    helperText
                  })
                )
              }
            )
          }
        })
      })
    },
    [dispatch]
  )

  const debouncedValidationsCaller = useMemo(
    () => debounce(callPendingValidationCallbacks, 500),
    [callPendingValidationCallbacks]
  )

  useEffect(() => {
    debouncedValidationsCaller(interactionModelData)
  }, [debouncedValidationsCaller, interactionModelData])
}
