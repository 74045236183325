import { UseQueryOptions, useQueries } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import {
  ActivityValue,
  FlatActivityModel,
  ValidationStatus
} from 'types/shared'

import { validateFlatActivityValue } from 'utils/validateActivityValue'

type UseValidateActivitiesProps = {
  activities: {
    activityModel: FlatActivityModel
    value?: ActivityValue
  }[]

  enabled?: boolean
}

export const useValidateActivities = ({
  activities,
  enabled
}: UseValidateActivitiesProps) => {
  const results = useQueries({
    queries: activities.map(
      ({ activityModel, value }) =>
        ({
          queryKey: ['validateFlatActivityValue', activityModel.uuid, value],
          queryFn: () => validateFlatActivityValue({ activityModel, value }),
          initialData: {
            validationStatus: 'pending' as ValidationStatus,
            helperText: ''
          },
          enabled
        }) satisfies UseQueryOptions
    )
  })

  type Result = (typeof results)[number]

  type ResultWithInitialData = Omit<Result, 'data'> & {
    data: NonNullable<Result['data']>
  }

  return results as ResultWithInitialData[]
}

type UseValidateActivityProps = {
  activityModel: FlatActivityModel
  value?: ActivityValue
  enabled?: boolean
  debounceTime?: number
}

export default function useValidateActivity({
  activityModel,
  value,
  enabled,
  debounceTime = 300
}: UseValidateActivityProps) {
  const [activities, setActivities] = useState([{ activityModel, value }])

  const debounceActivityValue = () => {
    const timeout = setTimeout(
      () => setActivities([{ activityModel, value }]),
      debounceTime
    )

    return () => clearTimeout(timeout)
  }

  useEffect(debounceActivityValue, [activityModel, value, debounceTime])

  const [{ data, ...queryInfo }] = useValidateActivities({
    activities,
    enabled
  })

  return {
    ...data,
    ...queryInfo
  }
}
