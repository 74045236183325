import jwtDecode from 'jwt-decode'
import apiAsync from 'utils/api-async'
import { isEmbedded, useParentPostMessage } from '@yes.technology/react-toolkit'
import { useAuthentication } from 'authentication'

type Credentials = {
  user_uuid: string
  organization_uuid: string
  applicationsystem_uuid: string
}

type UpgradeTokenRequestPayload = {
  credentials: Credentials
  token: string
}

type Token = {
  expiration_date: number
  token: string
}

type TokenResponse = {
  data: Token
  httpStatusCode: number
  success: boolean
}

type JWTData = {
  user_id: string
  email: string
  expiration_date: number
  credentials: Credentials
  roles: string[]
}

function postUpgradeToken(
  payload: UpgradeTokenRequestPayload
): Promise<TokenResponse> {
  return apiAsync.requestSync({
    method: 'POST',
    endpoint: `upgradeToken`,
    obj: payload
  })
}

const useUpgradeToken = () => {
  const { postMessage } = useParentPostMessage()

  const { updateAuthData, token } = useAuthentication()

  const upgradeToken = async (newCredentials?: Partial<Credentials>) => {
    if (!newCredentials || !token) return

    const payload = createUpgradeTokenPayload(token, newCredentials)

    if (payload) {
      const response = await postUpgradeToken(payload)
      const upgradedToken = response?.data?.token

      if (upgradedToken) {
        const decoded = jwtDecode<JWTData>(upgradedToken)

        apiAsync.setAuthToken(upgradedToken)

        updateAuthData({
          username: decoded.email,
          token: upgradedToken,
          expirationDate: decoded.expiration_date
        })

        if (isEmbedded()) {
          postMessage({
            type: 'updateAuthToken',
            token: upgradedToken,
            expirationDate: decoded.expiration_date
          })
        }
      }
    }
  }

  return upgradeToken
}

export default useUpgradeToken

function createUpgradeTokenPayload(
  token: string,
  newCredentials: Partial<Credentials>
): UpgradeTokenRequestPayload | undefined {
  const { credentials: oldCredentials } = jwtDecode<JWTData>(token) || {}

  if (!oldCredentials || !newCredentials) return

  return {
    token,
    credentials: {
      user_uuid: newCredentials.user_uuid || oldCredentials.user_uuid,
      organization_uuid:
        newCredentials.organization_uuid || oldCredentials.organization_uuid,
      applicationsystem_uuid:
        newCredentials.applicationsystem_uuid ||
        oldCredentials.applicationsystem_uuid
    }
  }
}
